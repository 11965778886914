import React, { useState, useRef, useCallback, useEffect } from 'react'
import Floater from 'react-floater'
import classNames from 'classnames'

import css from './styles.module.scss'
import { asText } from '../../utils/prismic'
import Image from '../Image'
import Wysiwyg from '../Wysiwyg'
import SectionScrollSpy from '../SectionScrollSpy'

const localStorageKey = 'CURRENT_HERO_TITLE_INDEX'

// component
// ==========================================================================================
const HomeHero = ({
  heroHeadings,
  heroBody,
  heroBackground,
  heroBackgroundSharp,
  heroCompanies,
}) => {
  const timeout = useRef()
  const [pulsate, setPulsate] = useState(true)
  const [showImage, setShowImage] = useState(false)
  const [heading, setHeading] = useState(false)

  const handleInteraction = useCallback(() => {
    setPulsate(false)
    clearTimeout(timeout.current)
    setTimeout(() => {
      setPulsate(true)
    }, 4000)

    return () => {
      clearTimeout(timeout.current)
    }
  }, [])
  // defer image load until page rendered
  useEffect(() => {
    setShowImage(true)
  }, [])
  // decide which title to show
  useEffect(() => {
    const headings = (heroHeadings || []).filter((item) => item.heading)
    const prevIndex = localStorage.getItem(localStorageKey)
      ? Number(localStorage.getItem(localStorageKey))
      : -1
    const currentIndex = prevIndex + 1 >= headings.length ? 0 : prevIndex + 1
    localStorage.setItem(localStorageKey, currentIndex)
    setHeading(headings[currentIndex]?.heading)
  }, [])

  return (
    <section className={css.wrap}>
      <SectionScrollSpy id="HomeHero" className={css.bottomSpy} isOnDark />

      <div className={css.inner}>
        <div className={css.imageWrap}>
          {showImage && (
            <Image
              image={heroBackground}
              {...heroBackgroundSharp}
              className={css.image}
              alt=""
            />
          )}
        </div>
        <div className="row">
          <div className="col-sm-12 col-lg-6 offset-lg-1">
            <div className={css.textWrap}>
              <div className={css.titleWrap}>
                {heading && <Wysiwyg content={heading} />}
              </div>
              <Wysiwyg content={heroBody} className="bigger" />
            </div>
          </div>
          <div className="col-sm-12 col-lg-4">
            <div
              className={classNames(css.mapMarkersWrap, {
                [css.pulsate]: pulsate,
              })}
            >
              {(heroCompanies || []).map(({ company }) => {
                if (!company) {
                  return null
                }

                const name = asText(company.name)
                const CustomFloater = () => {
                  return (
                    <div className={css.tooltip}>
                      {company.logo ? <Image image={company.logo} /> : name}
                    </div>
                  )
                }

                return (
                  <Floater
                    callback={handleInteraction}
                    component={CustomFloater}
                    event="hover"
                    placement="top"
                    eventDelay={0}
                    offset={8}
                    key={name}
                  >
                    <span className={css.mapMarker}>
                      <span className={css.pulsator} aria-hidden />
                      <span className="sr-only">{name}</span>
                    </span>
                  </Floater>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeHero
