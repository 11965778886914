import React, { useEffect } from 'react'
import { graphql, navigate } from 'gatsby'
import { get } from 'lodash'

import HomeHero from '../components/HomeHero'
import Slices from '../components/Slices'
import useTranslation from '../hooks/useTranslation'

export const query = graphql`
  query HomeQuery1($lang: String) {
    prismic {
      data: allHomes(lang: $lang) {
        edges {
          node {
            meta: _meta {
              id
              type
              uid
              lang
              alternateLanguages {
                uid
                lang
                type
              }
            }
            metaTitle: meta_title
            metaDescription: meta_description
            metaImage: meta_image
            heroHeadings: hero_headings {
              heading
            }
            heroBody: hero_body
            heroBackground: hero_background_image
            heroBackgroundSharp: hero_background_imageSharp {
              childImageSharp {
                fluid(maxWidth: 900) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            heroCompanies: hero_companies {
              company {
                ... on PRISMIC_Company {
                  name
                  logo: logo_blue
                }
              }
            }
            slices {
              ... on PRISMIC_HomeSlicesBody {
                type
                primary {
                  heading
                  body
                  link {
                    _linkType
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__Document {
                      meta: _meta {
                        id
                        uid
                        type
                        lang
                      }
                    }
                  }
                  linkLabel: link_label
                  image1
                  image1Sharp: image1Sharp {
                    childImageSharp {
                      fluid(maxWidth: 850) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                  image2
                  image2Sharp: image2Sharp {
                    childImageSharp {
                      fluid(maxWidth: 850) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_HomeSlicesCase_studies {
                type
                label
                primary {
                  heading
                  ctaLabel: cta_label
                  ctaLink: cta_link {
                    ... on PRISMIC__Document {
                      meta: _meta {
                        id
                        uid
                        type
                        lang
                      }
                    }
                  }
                }
                caseStudies: fields {
                  company
                  description
                  logo
                  link {
                    ... on PRISMIC__Document {
                      meta: _meta {
                        id
                        uid
                        type
                        lang
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_HomeSlicesNews {
                type
                label
                primary {
                  newsHeading: news_heading
                  eventHeading: event_heading
                  ctaLabel: cta_label
                  ctaLink: cta_link {
                    ... on PRISMIC__Document {
                      meta: _meta {
                        id
                        uid
                        type
                        lang
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_HomeSlicesPartners {
                type
                primary {
                  heading
                  body
                }
                fields {
                  logo
                }
              }
            }
          }
        }
      }
    }
  }
`

const PageContainer = (props) => {
  const { locale } = useTranslation()
  const data = get(props, 'data.prismic.data.edges[0].node')

  useEffect(() => {
    if (props.location.pathname.replace('/', '') !== locale)
      navigate(`/${locale}`)
  }, [])

  return (
    <>
      <HomeHero {...data} />
      <Slices {...data} />
    </>
  )
}

// PageContainer.propTypes = {
//   data: PropTypes.shape({
//     prismic: PropTypes.shape({
//       data: PropTypes.shape({}).isRequired,
//     }).isRequired,
//   }).isRequired,
// }

export default PageContainer
