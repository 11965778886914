import React, { useEffect, useContext, useState } from 'react'
import classNames from 'classnames'
import { useInView } from 'react-intersection-observer'

import css from './styles.module.scss'
import { StateContext } from '../../context/GlobalStateContext'

// component
// ==========================================================================================
const SectionScrollSpy = ({ className }) => {
  const [reveal, setReveal] = useState(false)
  const [ref, inView] = useInView({ threshold: 0 })
  const [{ colorChangingBackgroundActive }, dispatch] = useContext(StateContext)

  useEffect(() => {
    dispatch({
      type: 'setHasDarkBackground',
      updates: {
        hasDarkBackground: inView || colorChangingBackgroundActive,
      },
    })
  }, [dispatch, colorChangingBackgroundActive, inView])

  useEffect(() => {
    setTimeout(() => {
      setReveal(true)
    }, 0)
  }, [])

  return reveal ? (
    <div className={classNames(css.spy, css.Spy, className)} ref={ref} />
  ) : null
}

export default SectionScrollSpy
